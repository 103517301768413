<template>
  <v-menu
    :close-on-content-click="true"
    offset-y
    bottom
    left
    origin="top right"
    max-height="840"
    transition="scale-transition"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn text v-bind="attrs" v-on="on">
        <v-badge bordered color="red" overlap>
          <template v-slot:badge>
            <span> {{ nbalerts }} </span>
          </template>
          <v-icon>mdi-bell</v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-card>
      <div v-for="(item, i) in list" :key="i" @click="goto(item)">
        <v-alert
          color="orange"
          dense
          text
          border="left"
          elevation="2"
          colored-border
          icon="mdi-alert"
          class="my-1"
        >
          <a>{{ item.message }}</a>
        </v-alert>
        <!-- <v-list-item-title v-text="item.message"></v-list-item-title> -->
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import ALERTS from "@/graphql/Stat/ALERTS.gql";
export default {
  name: "DefaultNotifications",

  data: () => ({
    notifications: [],
    ref: false,
  }),
  watch: {
    "$store.state.changed": {
      handler() {
        if (this.$store.state.changed) this.refresh();
      },
    },
    "$store.state.alert": {
      handler() {
        if (this.$store.state.alert) this.refresh();
      },
    },
  },
  created() {
    this.refresh();
  },

  computed: {
    list() {
      return this.notifications.filter(
        (elm) =>
          (elm.code <= 6 && this.$store.state.auth.includes(elm.auth)) ||
          elm.code > 6 ||
          this.$store.state.isadmin
      );
    },
    nbalerts() {
      // sum data in give key (property)
      return this.list ? this.list.reduce((a, b) => a + (b["nb"] || 0), 0) : 0;
    },
  },
  methods: {
    async refresh() {
      let r = await this.$requette(ALERTS);
      if (r) {
        this.notifications = r.data.alerts;
        this.$store.dispatch("alert", false);
      }
    },
    goto(item) {
      if (
        this.$store.state.auth.includes(item.auth) ||
        this.$store.state.isadmin
      ) {
        if (this.$router.currentRoute.name != item.menu)
          this.$router.push({
            name: item.menu,
            params: { filtre: item.status_name },
          });
        else {
          this.$store.dispatch("Filtre", item.status_name);
        }
      }
    },
  },
};
</script>
